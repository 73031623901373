import axios from 'axios';
import { DB_API_LIVE } from '../config';

const axiosServices = axios.create({
    baseURL: DB_API_LIVE.authAPI
});

axiosServices.interceptors.request.use((config) => {
    const serviceToken = window.localStorage.getItem('serviceToken');

    config.headers.Authorization = serviceToken ? `Bearer ${serviceToken}` : '';
    return config;
});
// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error.response || 'Wrong Services')
);

export default axiosServices;
