// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUser, IconFiles, IconCreditCard, IconApi, IconListCheck } from '@tabler/icons';

// constant
const icons = { IconListCheck, IconCreditCard, IconApi, IconFiles, IconUser };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'verify',
            title: 'Verify',
            type: 'item',
            url: '/verify',
            icon: icons.IconListCheck,
            breadcrumbs: false
        },
        {
            id: 'credits',
            title: 'Credits',
            type: 'item',
            url: '/credits',
            icon: icons.IconCreditCard,
            breadcrumbs: false
        },
        {
            id: 'api',
            title: 'API',
            type: 'item',
            url: '/api',
            icon: icons.IconApi,
            breadcrumbs: false
        },
        {
            id: 'files',
            title: 'Files',
            type: 'item',
            url: '/files',
            icon: icons.IconFiles,
            breadcrumbs: false
        },
        {
            id: 'user',
            title: 'Profile',
            type: 'item',
            url: '/profile',
            icon: icons.IconUser,
            breadcrumbs: false
        }
        // {
        //     id: 'documentation',
        //     title: <FormattedMessage id="documentation" />,
        //     type: 'item',
        //     url: 'https://codedthemes.gitbook.io/berry/',
        //     icon: icons.IconHelp,
        //     external: true,
        //     target: true
        // },
        // {
        //     id: 'roadmap',
        //     title: <FormattedMessage id="roadmap" />,
        //     type: 'item',
        //     url: 'https://codedthemes.gitbook.io/berry/roadmap',
        //     icon: icons.IconSitemap,
        //     external: true,
        //     target: true
        // }
    ]
};

export default other;
