const env = process.env.NODE_ENV;

export const DB_API_LIVE =
    env === 'production'
        ? {
              host: 'https://cloud.cleanverify.com',
              authAPI: 'https://cloud.cleanverify.com/api/auth',
              fileAPI: 'https://cloud.cleanverify.com/api/file',
              socketio: 'https://cloud.cleanverify.com'
          }
        : {
              host: 'http://localhost:8000',
              authAPI: 'http://localhost:8000/api/auth',
              fileAPI: 'http://localhost:8000/api/file',
              socketio: 'http://localhost:8000'
          };

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/verify';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'theme1', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
