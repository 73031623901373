import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

const Error404 = Loadable(lazy(() => import('views/pages/errors/404')));
const Error401 = Loadable(lazy(() => import('views/pages/errors/401')));

const ErrorRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '*',
            element: <Error404 />
        },
        {
            path: '/401',
            element: <Error401 />
        }
    ]
};

export default ErrorRoutes;
