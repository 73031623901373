// routing
import Routes from 'routes';
import { ErrorBoundary } from 'react-error-boundary';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import ErrorFallback from './views/pages/errors/ErrorFallback';
// auth provider
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { AuthCleanVerifyProvider as AuthProvider } from 'contexts/AuthCleanVerifyContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                <NavigationScroll>
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <AuthProvider>
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </AuthProvider>
                    </ErrorBoundary>
                </NavigationScroll>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
